import React from "react";

// from https://loading.io/css/
class Spinner extends React.Component {
  render() {
    return (
      <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    );
  }
}

export default Spinner;
