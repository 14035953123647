import React from "react"

class Footer extends React.Component {
  render() {
    return (
      <div className="footer-component">
        <p>Copyright &copy; { (new Date()).getFullYear() } Supernova Capital. All Rights Reserved. <br/><a href="/about">About</a> <span className="separator">|</span> <a href="/privacy-policy">Privacy Policy</a> <span className="separator">|</span> <a href="/terms-of-service">Terms of Service</a></p>
      </div>
    );
  }
}

export default Footer;
