import PropTypes from "prop-types";
import React from "react";
import ReactDOM from "react-dom";
import Map from "components/Map";
import Mailboxes from "components/Mailboxes";
import Sidebar from "components/Sidebar";

class LocationRoot extends React.Component {
  render() {
    return <Mailboxes requestCurrentLocation={this.props.requestCurrentLocation} path={this.props.path} scope={this.props.scope}>{({mailboxes, overflow, fetchBounds, selectedMailbox, selectMailbox, geolocating, showModal}) => [
      ReactDOM.createPortal(
        <Map showModal={showModal} mailboxes={mailboxes} overflow={overflow} fetchBounds={fetchBounds} selectedMailbox={selectedMailbox} selectMailbox={selectMailbox} geolocating={geolocating} minLat={this.props.initialMinLat} maxLat={this.props.initialMaxLat} minLon={this.props.initialMinLon} maxLon={this.props.initialMaxLon} lat={this.props.lat} lon={this.props.lon}/>,
        document.getElementById("location-map")
      ),
      ReactDOM.createPortal(
        <Sidebar mailboxes={mailboxes} overflow={overflow} selectMailbox={selectMailbox} />,
        document.getElementById("location-sidebar-mount")
      )
    ]}</Mailboxes>;
  }
}

LocationRoot.propTypes = {
  initialMinLat: PropTypes.number.isRequired,
  initialMinLon: PropTypes.number.isRequired,
  initialMaxLat: PropTypes.number.isRequired,
  initialMaxLon: PropTypes.number.isRequired,
  requestCurrentLocation: PropTypes.bool.isRequired,
  scope: PropTypes.string.isRequired,
  lat: PropTypes.number,
  lon: PropTypes.number,
  path: PropTypes.string.isRequired,
};

export default LocationRoot;
