import {debounce} from "lodash";
import PropTypes from "prop-types";
import React from "react";

class Mailboxes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      mailboxes: [],
      overflow: false,
      selectedMailbox: undefined,
      geolocating: props.requestCurrentLocation
    };
  }

  componentDidMount() {
    if (this.props.requestCurrentLocation) {
      this.fetchCurrentLocation();
    }
  }

  fetchCurrentLocation() {
    navigator.geolocation.getCurrentPosition((position) => {
      document.location = `${this.props.path}?lat=${position.coords.latitude}&lon=${position.coords.longitude}`;
    }, (error) => {
      this.setState({geolocating: false});
      // if fetchCurrentLocation fails, show user the modal prompting
      // for an address
      this.showModal();
    }, {
      enableHighAccuracy: false,
      timeout: 5000,
      maximumAge: Infinity
    });
  }

  showModal() {
    $("#search-modal").modal();
  }

  fetchBounds = debounce((bounds) => {
    if (this.xhr) {
      this.xhr.abort();
    }

    const params = {
      minLat: Math.min(bounds.getSouth(), bounds.getNorth()),
      maxLat: Math.max(bounds.getSouth(), bounds.getNorth()),
      minLon: Math.min(bounds.getWest(), bounds.getEast()),
      maxLon: Math.max(bounds.getWest(), bounds.getEast()),
      scope: this.props.scope
    };

    if (this.state.selectedMailbox) {
      params.include = this.state.selectedMailbox;
    }

    this.xhr = $.getJSON("/mailboxes", params, (response) => {
      this.setState({
        mailboxes: response.mailboxes,
        overflow: response.overflow
      });
    });
  }, 500, {leading: true, trailing: true})

  selectMailbox = (locationId) => {
    this.setState({selectedMailbox: locationId});
  }

  render() {
    return this.props.children({
      mailboxes: this.state.mailboxes,
      overflow: this.state.overflow,
      fetchBounds: this.fetchBounds,
      selectedMailbox: this.state.selectedMailbox,
      selectMailbox: this.selectMailbox,
      geolocating: this.state.geolocating,
      showModal: this.showModal
    });
  }
}

Mailboxes.propTypes = {
  requestCurrentLocation: PropTypes.bool.isRequired,
  scope: PropTypes.string.isRequired,
  path: PropTypes.string,
}

export default Mailboxes;
