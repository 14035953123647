


const vendorMap = [
  
    ["WALGREENS", "/assets/vendor-walgreens-f85e60726666c953e5ef9db536da44ae754bc49deb98e1ee16e2ccf204c6d0a0.png"],
  
    ["WAL-MART SUPERCENTER", "/assets/vendor-walmart-bd9e34921fcff8d31dd0eec67b5e62267d3b9d7c1f26f2fa78eebadb80e5eac3.png"],
  
    ["CASEY'S GENERAL STORE", "/assets/vendor-caseys-beb2e4adccb179d88aae6d13f4436df265e56ad94c7f11ec3d616164f548c849.png"],
  
    ["PUBLIX SUPERMARKETS", "/assets/vendor-publix-56fd798d4d3a8334300acf6711d9496b04277b91ea71278c913e046e9030afdd.png"],
  
    ["SAFEWAY", "/assets/vendor-safeway-ded55f32dcd59b4420adb015ed9cd107e507aba163ff8c9cf7446e2113137e7f.png"],
  
    ["SHAW'S", "/assets/vendor-shaws-1b40fdf76c0e4c59eacc7a43b99cae77d27ec697bfa223bf7f64c11a337e9fe3.png"],
  
    ["CVS", "/assets/vendor-cvs-2cc35140aa2bf04eaa1504b66554860aa64c0b5650dbbeb35c5158fe2894620d.png"],
  
];

export default function(name) {
  for (var pair of vendorMap) {
    if (name.indexOf(pair[0]) != -1) {
      return pair[1];
    }
  }
  return undefined;
}
