import PropTypes from "prop-types";
import React from "react";
import Map from "components/Map";
import Mailboxes from "components/Mailboxes";

class MapRoot extends React.Component {
  render() {
    return <Mailboxes requestCurrentLocation={false} scope={this.props.scope}>{({mailboxes, overflow, fetchBounds, selectedMailbox, selectMailbox, geolocating}) =>
        <Map mailboxes={mailboxes} overflow={overflow} fetchBounds={fetchBounds} selectedMailbox={selectedMailbox} selectMailbox={selectMailbox} geolocating={geolocating} minLat={this.props.initialMinLat} maxLat={this.props.initialMaxLat} minLon={this.props.initialMinLon} maxLon={this.props.initialMaxLon} />
    }</Mailboxes>;
  }
}

MapRoot.propTypes = {
  initialMinLat: PropTypes.number.isRequired,
  initialMinLon: PropTypes.number.isRequired,
  initialMaxLat: PropTypes.number.isRequired,
  initialMaxLon: PropTypes.number.isRequired,
  scope: PropTypes.string.isRequired
};

export default MapRoot;
