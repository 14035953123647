import React from "react";
import PropTypes from "prop-types";
import Spinner from "components/Spinner";

class MyLocationButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
  }

  handleClick = () => {
    this.setState({loading: true});
    navigator.geolocation.getCurrentPosition((position) => {
      document.location = `${this.props.path}?lat=${position.coords.latitude}&lon=${position.coords.longitude}`;
    }, (error) => {
      alert(error.message);
      this.setState({loading: false});
    });
  };

  render() {
    const text = this.props.text ? this.props.text : "Locate Me";
    return (
      <button className={`btn btn-default ${this.props.block ? "btn-block" : ""}`} onClick={this.handleClick} type="button" disabled={this.state.loading}><span className="glyphicon glyphicon-screenshot"></span> {this.state.loading ? "Locating..." : text}</button>
    );
  }
}

MyLocationButton.defaultProps = {
  path: "/location"
};

MyLocationButton.propTypes = {
  path: PropTypes.string.isRequired
};

export default MyLocationButton;
