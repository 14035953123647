import L from "leaflet";
import PropTypes from "prop-types";
import React from "react";
import { Map as LeafletMap, TileLayer, Marker, Popup } from "react-leaflet";
import Spinner from "components/Spinner";
import { collectionBoxMarker, uspsIcon, markerIcon, markerIcon2x, markerShadow, iconStar} from "../images";
import mapVendor from "vendors";

const collectionBoxIcon = L.icon({
  iconUrl: collectionBoxMarker,
  iconSize: [24, 27],
  "shadowUrl": markerShadow,
  "shadowSize": [41, 41],
  shadowAnchor: [7, 28]
});

const postOfficeIcon = L.icon({
  iconUrl: uspsIcon,
  iconSize: [32, 32]
});

const starIcon = L.icon({
  iconUrl: iconStar,
  iconSize: [32, 32]
});

const defaultIcon = L.icon({
  "iconUrl": markerIcon,
  "iconRetinaUrl": markerIcon2x,
  "shadowUrl": markerShadow,
  "iconSize": [
    25,
    41
  ],
  "iconAnchor": [
    12,
    41
  ],
  "popupAnchor": [
    1,
    -34
  ],
  "tooltipAnchor": [
    16,
    -28
  ],
  "shadowSize": [
    41,
    41
  ]
});

function iconForMailbox(mb) {
  if (mb.type === "FullPostOffice" || mb.type === "VillagePostOffice") {
    return postOfficeIcon;
  } else if (mb.type === "CollectionBox") {
    return collectionBoxIcon;
  } else if (mb.type === "AlternatePostOffice") {
    const img = mapVendor(mb.name);
    if (img) {
      return L.icon({
        iconUrl: img,
        iconSize: [24, 24]
      });
    }
  }
  return defaultIcon;
}

class MapMarker extends React.Component {
  constructor(props) {
    super(props);
    this.leafletRef = React.createRef();
  }

  handleOpen = () => {
    this.props.selectMailbox(this.props.mailbox.location_id);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.selectedMailbox != this.props.mailbox.location_id && this.props.selectedMailbox === this.props.mailbox.location_id) {
      if (this.leafletRef) {
        this.leafletRef.current.leafletElement.openPopup();
      }
    }
  }

  render() {
    const mb = this.props.mailbox;
    return (
      <Marker position={[mb.latitude, mb.longitude]} icon={iconForMailbox(mb)} ref={this.leafletRef}>
        <Popup onOpen={this.handleOpen}>
          <div className="map-marker">
            <div className="map-marker__title">{ mb.title }</div>
            <div className="map-marker__address_1">{ mb.address_1 }</div>
            <div className="map-marker__city">{ mb.city_and_state } { mb.zip_5 }</div>
            <div className="map-marker__directions"><a target="_blank" href={ mb.directions }><span className="glyphicon glyphicon-map-marker"></span> Directions</a></div>
          </div>
        </Popup>
      </Marker>
    );
  }
}

class Geolocating extends React.Component {
  render() {
    return (
      <div className="geolocating">
        <div><Spinner /></div>
        <div>Determining current location...</div>
        <div><a onClick={this.props.showModal}>or enter an address...</a></div>
      </div>
    );
  }
}

Geolocating.propTypes = {
  showModal: PropTypes.func.isRequired
};

class Map extends React.Component {
  constructor(props) {
    super(props);
    this.leafletMapRef = React.createRef();
  }

  componentDidMount() {
    this.handleBoundsChanged();
  }

  onViewportChanged = (viewport) => {
    this.handleBoundsChanged();
  }

  handleBoundsChanged() {
    if (this.leafletMapRef.current) {
      const bounds = this.leafletMapRef.current.leafletElement.getBounds();
      this.props.fetchBounds(bounds);
    }
  }

  render () {
    const bounds = [[this.props.minLat, this.props.minLon], [this.props.maxLat, this.props.maxLon]];
    return (
      <div className="map">
        <LeafletMap bounds={bounds} minZoom={10} maxZoom={18} onViewportChanged={this.onViewportChanged} ref={this.leafletMapRef}>
          <TileLayer
            attribution = {'© ' + new Date().getFullYear() + ' Microsoft, © ' + new Date().getFullYear() + ' TomTom'}
            url="https://atlas.microsoft.com/map/tile/png?api-version=1&layer=basic&style=main&tileSize=512&zoom={z}&x={x}&y={y}&subscription-key={subscriptionKey}"
            tileSize = {512}
            zoomOffset = {-1}
            id = 'azuremaps.road'
            crossOrigin = {true}
            subscriptionKey = 'G1bmfR9ooFcTwC-f7L5WC-u4FH1fuOKZ1KoYaOk9gYw'
      />
          { this.props.mailboxes.map((mb) =>
            <MapMarker mailbox={mb} key={mb.location_id} selectedMailbox={this.props.selectedMailbox} selectMailbox={this.props.selectMailbox} />
          )}
          { this.props.lat && this.props.lon && <Marker position={[this.props.lat, this.props.lon]} icon={starIcon}><Popup>You are here (approximately)</Popup></Marker> }
        </LeafletMap>
        { this.props.overflow && <div className="map__overflow">Some locations hidden. Zoom in to see all.</div> }
        { this.props.geolocating ? <Geolocating showModal={this.props.showModal}/> : undefined }
      </div>
    );
  }
}

Map.propTypes = {
  lat: PropTypes.number,
  lon: PropTypes.number,
  minLat: PropTypes.number.isRequired,
  maxLat: PropTypes.number.isRequired,
  minLon: PropTypes.number.isRequired,
  maxLon: PropTypes.number.isRequired,
  fetchBounds: PropTypes.func.isRequired,
  selectedMailbox: PropTypes.string,
  selectMailbox: PropTypes.func.isRequired,
  geolocating: PropTypes.bool.isRequired,
  showModal: PropTypes.func
};

export default Map;
