import {DateTime} from "luxon";
import React from "react"
import PropTypes from "prop-types"
import Footer from "components/Footer";
import {collectionBoxMarker, uspsIcon, stampsComLogo} from "images";
import mapVendor from "vendors";

class MailboxIcon extends React.Component {
  render() {
    if (this.props.type == "CollectionBox") {
      return <img width="21" height="24" src={collectionBoxMarker} />;
    } else if (this.props.type == "FullPostOffice" || this.props.type == "VillagePostOffice") {
      return <img width="24" height="24" src={uspsIcon} />;
    } else if (this.props.type == "AlternatePostOffice") {
      const img = mapVendor(this.props.name);
      if (img) {
        return <img width="24" height="24" src={img} />;
      }
    }
    return false;
  }
}

class Mailbox extends React.Component {
  handleClick = (locationId) => {
    this.props.selectMailbox(locationId);
  };

  renderHours() {
    if (this.props.type != "CollectionBox") {
      return false;
    }

    const now = DateTime.local();
    const day = ["", "mon", "tue", "wed", "thu", "fri", "sat", "sun"][now.weekday];
    const hour = this.props[day];
    const longWeekday = now.setLocale('en-US').toLocaleString({weekday: 'long'}) + "s";

    if (!hour) {
      return <div className="sidebar__mailbox__hours">No collection on { longWeekday }</div>;
    }

    const dt = DateTime.fromFormat(hour, "HHmm")

    return (
      <div className="sidebar__mailbox__hours">
      On { longWeekday }, collected at <span className="text-nowrap">{ dt.setLocale('en-US').toLocaleString(DateTime.TIME_SIMPLE) }</span>
      </div>
    );
  }

  renderMoreInfo() {
    if (!this.props.path) {
      return null;
    }
    return (
      <div className="sidebar__mailbox__more-info">
        <a href={this.props.path}><span className="glyphicon glyphicon-info-sign"></span> More info (including hours)</a>
      </div>
    );
  }

  render() {
    return (
      <div className="sidebar__mailbox">
        <div className="sidebar__mailbox__title"><a onClick={this.handleClick.bind(null, this.props.location_id)}><MailboxIcon type={this.props.type} name={this.props.name}/> { this.props.title }</a></div>
        <div className="sidebar__mailbox__address_1">{ this.props.address_1 }</div>
        <div className="sidebar__mailbox__city">{ this.props.city_and_state } { this.props.zip_5 }</div>
        <div className="sidebar__mailbox__directions"><a target="_blank" href={ this.props.directions }><span className="glyphicon glyphicon-map-marker"></span> Directions</a></div>
      { this.renderHours() }
      { this.renderMoreInfo() }
      </div>
    );
  }
}

class StampsAd extends React.Component {
  render() {
    const stampsUrl = window.stampsComUrl;
    return (
      <div className="sidebar__stamps-ad">
        <a href={stampsUrl}><img className="sidebar__stamps-ad-image" src={ stampsComLogo } /></a>
        <div style={{fontWeight: "bold"}}><a href={stampsUrl}>Need to buy Stamps?</a></div>
        <p style={{margin: 0}}>Instantly print postage at home at <a href={stampsUrl}>Stamps.com</a>. <a href={stampsUrl}>Get a 4 week trial</a> and mail immediately. <a href={stampsUrl}>Click here to start.</a></p>
      </div>
    );
  }
}

const AD_SPLICE_POINTS = [1, 3, 6, 10, 15];

class Sidebar extends React.Component {
  render () {
    const items =  this.props.mailboxes.map((mb) =>
      <Mailbox selectMailbox={this.props.selectMailbox} key={mb.location_id} {...mb} />
    );

    AD_SPLICE_POINTS.sort().reverse().forEach(point => {
      if (items.length >= point) {
        items.splice(point, 0, <StampsAd key={`ad${point}`} />);
      }
    });

    if (items.length == 0) {
      items.push(<StampsAd key="ad" />);
    }

    return (
      <div className="sidebar">
      <div className={`sidebar__subline ${this.props.overflow ? 'error' : ''}`}>{ this.props.mailboxes.length} visible on map { this.props.overflow ?<span><br/>Some locations hidden. Zoom in to see all.</span> : "" }</div>
      { items }
      </div>
    );
  }
}

Sidebar.propTypes = {
  mailboxes: PropTypes.array.isRequired,
  overflow: PropTypes.bool.isRequired,
  selectMailbox: PropTypes.func.isRequired
};

export default Sidebar;
